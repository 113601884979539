/**

Component for showing snow record information for segment

Luonut: Markku Nirkkonen

Päivityshistoria

13.12.2021 Juho Kumara
Updated styling

5.12.2021 Juho Kumara
Snow type names, icons and skiiability values are now shown correctly.

7.11.2021 Oona Laitamaki
Updated layout design

23.10.2021 Oona Laitamaki
Changed layout design & added relative timestamp, skiability elements and sub snowtypes

18.10.2021
Moved here from Info.js

23.2 2023 otso tikkkanen
Added english version

**/

import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Link from "@material-ui/core/Link";
import DisplaySnowType from "./DisplaySnowType";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import GlobalContext from "../../context/GlobalContext";
import translations from "../../translations/translations";
import getTranslationKey from "../../translations/getTranslationKey";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  addPadding: {
    padding: "5px 15px",
  },
  close: {
    color: "white",
    left: "85%",
  },
  divider: {
    height: "1px",
    background: "#707070",
    margin: "15px 5px 15px 5px",
  },
  snowInfo: {
    alignContent: "center",
  },
  bigHeaders: {
    fontFamily: "Donau",
    letterSpacing: 4,
    textTransform: "uppercase",
    fontWeight: 600,
    display: "block",
    color: "white",
  },
  smallHeaders: {
    fontFamily: "Donau",
    letterSpacing: 2,
    textTransform: "uppercase",
    fontWeight: 600,
    display: "block",
    fontSize: "medium",
  },
  normalText: {
    fontFamily: "Donau",
    letterSpacing: 2,
    fontWeight: 300,
    fontSize: "medium",
  },
  textContainer: {
    overflowY: "scroll",
    paddingLeft: "5px",
    maxHeight: "140px",
  },
  smallText: {
    fontFamily: "Donau",
    letterSpacing: 2,
    fontWeight: 100,
    fontSize: "small",
    marginLeft: "5px",
  },
  mediumText: {
    fontFamily: "Donau",
    letterSpacing: 2,
    fontWeight: 300,
    fontSize: "medium",
  },
  dangerIcon: {
    verticalAlign: "middle",
  },
  sponsorContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  sponsor: {
    maxWidth: "40px",
    maxHeight: "40px",
    padding: "10px",
  },
  reviewButton: {
    borderRadius: "30px",
    textTransform: "none",
    width: "100%",
    maxWidth: "280px",
    maxHeight: "40px",
    marginBottom: "5px",
    fontFamily: "Donau",
    color: "#FFF",
    letterSpacing: 2,
    fontWeight: 300,
    fontSize: "medium",
  },
  authorTag: {
    padding: "4px",
    margin: "4px",
    fontFamily: "Donau",
    letterSpacing: 2,
    fontWeight: 300,
    fontSize: "small",
    justifyContent: "center",
    borderRadius: "7px",
  },
  timeStamp: {
    fontFamily: "Donau",
    letterSpacing: 2,
    fontWeight: 600,
    fontSize: "medium",
    marginLeft: "5px",
  },
  expandOpen: {
    color: "#FFF",
    transform: "rotate(90deg)",
  },
  expandClosed: {
    color: "#FFF",
    transform: "rotate(-90deg)",
  },
  expandButton: {
    borderRadius: "30px",
    textTransform: "none",
    width: "150px",
    height: "30px",
    marginBottom: "5px",
    backgroundColor: "#374B6A",
  },
  snowComment: {
    borderRadius: "20px",
    backgroundColor: "rgba(186, 186, 186, 0.31)",
    paddingLeft: "20px",
    margin: 5,
  },
}));

function getRelativeTimestamp(current, previous, language) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    if (Math.round(elapsed / 1000) == 1) {
      return `1  ${translations["secondAgo"][language]}`;
    }
    return `${Math.round(elapsed / 1000)} ${
      translations["secondsAgo"][language]
    }`;
  } else if (elapsed < msPerHour) {
    if (Math.round(elapsed / msPerMinute) == 1) {
      return `1  ${translations["minuteAgo"][language]}`;
    }
    return `${Math.round(elapsed / msPerMinute)} ${
      translations["minutesAgo"][language]
    }`;
  } else if (elapsed < msPerDay) {
    if (Math.round(elapsed / msPerHour) == 1) {
      return `1  ${translations["hourAgo"][language]}`;
    }
    return `${Math.round(elapsed / msPerHour)} ${
      translations["hoursAgo"][language]
    }`;
  } else if (elapsed < msPerMonth) {
    if (Math.round(elapsed / msPerDay) == 1) {
      return `1  ${translations["dayAgo"][language]}`;
    }
    return `${Math.round(elapsed / msPerDay)} ${
      translations["daysAgo"][language]
    }`;
  } else if (elapsed < msPerYear) {
    if (Math.round(elapsed / msPerMonth) == 1) {
      return `1  ${translations["monthAgo"][language]}`;
    }
    return `${Math.round(elapsed / msPerMonth)} ${
      translations["monthsAgo"][language]
    }`;
  } else {
    if (Math.round(elapsed / msPerYear) == 1) {
      return `1  ${translations["yearAgo"][language]}`;
    }
    return `${Math.round(elapsed / msPerYear)} ${
      translations["yearsAgo"][language]
    }`;
  }
}

function SnowRecordView({
  segmentdata,
  writeReviewEnabled,
  openForm,
  openFeedback,
  close,
  signedUser,
  sponsors,
}) {
  const classes = useStyles();
  // Avalanche warning LINK
  const url = "https://www.pallaksenpollot.com/";
  // 0px  XS  600px  SM  900px  MD
  const isXS = useMediaQuery({ query: "(max-width: 599px)" });
  //const isSM = useMediaQuery({ query: "(min-width: 600px) and (max-width: 900px)" });
  const description =
    segmentdata.update === null || segmentdata.update === undefined
      ? ""
      : segmentdata.update.description;

  const isEmpty =
    segmentdata.update === null || segmentdata.update === undefined
      ? true
      : checkIfEmpty();

  const [expanded, setExpanded] = React.useState(false);
  const [guideTime, setGuideTime] = React.useState("");
  const [userTimes, setUserTimes] = React.useState([]);

  const { language } = React.useContext(GlobalContext);
  //Calculate all timestamps only in the first render
  React.useEffect(() => {
    // Parsitaan päivämäärä ja aika päivityksestä, mikäli päivitys löytyy
    if (segmentdata.update !== null && segmentdata.update !== undefined) {
      let currentTime = new Date();

      if (
        segmentdata.update.time !== null &&
        segmentdata.update.time !== undefined
      ) {
        // Datasta saadaan viimeisin päivitysaika
        let latestUpdateTime = new Date(segmentdata.update.time);
        let guideUpdateTime = `${
          translations["lastUpdated"][language]
        } ${getRelativeTimestamp(currentTime, latestUpdateTime, language)}`;
        setGuideTime(guideUpdateTime);
      }

      let userTime1 = "";
      let userTime2 = "";
      let userTime3 = "";

      if (segmentdata.update.a1Time !== null) {
        // Datasta saadaan viimeisin päivitysaika
        let latestUpdateTime = new Date(segmentdata.update.a1Time);
        userTime1 = `${
          translations["lastUpdated"][language]
        } ${getRelativeTimestamp(currentTime, latestUpdateTime, language)}`;
      }
      if (segmentdata.update.a2Time !== null) {
        // Datasta saadaan viimeisin päivitysaika
        let latestUpdateTime = new Date(segmentdata.update.a2Time);
        userTime2 = `${
          translations["lastUpdated"][language]
        } ${getRelativeTimestamp(currentTime, latestUpdateTime, language)}`;
      }
      if (segmentdata.update.a3Time !== null) {
        // Datasta saadaan viimeisin päivitysaika
        let latestUpdateTime = new Date(segmentdata.update.a3Time);
        userTime3 = `${
          translations["lastUpdated"][language]
        } ${getRelativeTimestamp(currentTime, latestUpdateTime, language)}`;
      }

      setUserTimes([userTime1, userTime2, userTime3]);
    }
  }, [segmentdata]);

  // Gets boolean value of snowtype visibility, by given index (indices 1&2 are primary types, 3&4 are secondary types, 5 user type)
  function isEnabled(index) {
    if (segmentdata.update !== null && segmentdata.update !== undefined) {
      switch (index) {
        case 1:
          if (segmentdata.update.snow1 !== undefined) return true;
          break;
        case 2:
          if (segmentdata.update.snow2 !== undefined) return true;
          break;
        case 3:
          if (segmentdata.update.snow3 !== undefined) return true;
          break;
        case 4:
          if (segmentdata.update.snow4 !== undefined) return true;
          break;
        case 5:
          if (segmentdata.update.snow5 !== undefined) return true;
          break;
        default:
          break;
      }

      return false;
    }
  }

  function ifGuideInfoExists() {
    if (isEnabled(1) || isEnabled(2) || isEnabled(3) || isEnabled(4)) {
      return true;
    }
    return false;
  }

  function ifUserInfoExists() {
    if (isEnabled(5)) {
      return true;
    }
    return false;
  }

  function checkIfEmpty() {
    if (ifGuideInfoExists() || ifUserInfoExists()) {
      return false;
    }
    return true;
  }

  //Set expanded
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  var dangerimage;
  var dangertext;

  // Alustetaan komponentit, mikäli valitulla segmentillä on lumivyöryvaara
  if (segmentdata !== null) {
    if (segmentdata.avalancheDanger) {
      // Lumivyöryvaaran merkin tiedostonimi on !.png
      dangerimage = (
        <Grid
          item
          xs={12}
          sm={12}
          style={{ backgroundColor: "orange", margin: 0, paddingBottom: "1%" }}
        >
          <img
            className={classes.dangerIcon}
            style={isXS ? { maxWidth: "15%" } : { maxWidth: "8%" }}
            src={process.env.PUBLIC_URL + "/icons/avalanche.svg"}
            alt="lumivyöryvaaran logo"
          />
        </Grid>
      );
      dangertext = (
        <div>
          <Typography
            className={classes.normalText}
            variant="subtitle1"
            color="error"
            display="inline"
          >
            {translations["checkAvalanceStatus"][language]}{" "}
          </Typography>
          <Link
            className={classes.normalText}
            href={url}
            rel="noopener noreferrer"
            variant="subtitle1"
            display="inline"
          >
            {url}
          </Link>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              backgroundColor: "orange",
              margin: 0,
              paddingBottom: "1%",
            }}
          ></Grid>
        </div>
      );
    } else {
      dangerimage = <div />;
      dangertext = null;
    }
  }

  function StonesAndBranchesWithHeader(props) {
    return (
      <div>
        {props.option === 1 && (
          <Grid
            item
            sm={6}
            style={{ display: "flex", padding: isXS ? "0px 15px" : "0px" }}
          >
            <DisplaySnowType
              snowType={21}
              name={translations["stones"][language]}
              skiability={null}
              Main={false}
              Guide={false}
            />
          </Grid>
        )}
        {props.option === 2 && (
          <Grid
            item
            sm={6}
            style={{ display: "flex", padding: isXS ? "0px 15px" : "0px" }}
          >
            <DisplaySnowType
              snowType={22}
              name={translations["branches"][language]}
              skiability={null}
              Main={false}
              Guide={false}
            />
          </Grid>
        )}
        {props.option === 3 && (
          <Grid style={{ display: "flex", padding: isXS ? "0px 15px" : "0px" }}>
            <DisplaySnowType
              snowType={21}
              name={translations["stones"][language]}
              skiability={null}
              Main={false}
              Guide={false}
            />
            <DisplaySnowType
              snowType={22}
              name={translations["branches"][language]}
              skiability={null}
              Main={false}
              Guide={false}
            />
          </Grid>
        )}
      </div>
    );
  }

  function StonesAndBranches(props) {
    return (
      <>
        {props.option === 1 && (
          <Grid item xs={6} sm={6}>
            <CardMedia
              component={"img"}
              style={{ display: "block", width: "85%" }}
              src={
                process.env.PUBLIC_URL +
                "/icons/snowtypes-and-harms/" +
                21 +
                ".svg"
              }
              alt="lumityypin logo"
            />
          </Grid>
        )}
        {props.option === 2 && (
          <Grid item xs={6} sm={6}>
            <CardMedia
              component={"img"}
              style={{ display: "block", width: "85%" }}
              src={
                process.env.PUBLIC_URL +
                "/icons/snowtypes-and-harms/" +
                22 +
                ".svg"
              }
              alt="lumityypin logo"
            />
          </Grid>
        )}
        {props.option === 3 && (
          <Grid item xs={12} sm={12} container>
            <Grid item xs={6} sm={6}>
              <CardMedia
                component={"img"}
                style={{ display: "block", width: "85%" }}
                src={
                  process.env.PUBLIC_URL +
                  "/icons/snowtypes-and-harms/" +
                  21 +
                  ".svg"
                }
                alt="lumityypin logo"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <CardMedia
                component={"img"}
                style={{ display: "block", width: "85%" }}
                src={
                  process.env.PUBLIC_URL +
                  "/icons/snowtypes-and-harms/" +
                  22 +
                  ".svg"
                }
                alt="lumityypin logo"
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  function TimeStamp(props) {
    let timeText = "";
    if (props.fromGuide) {
      timeText = guideTime;
    } else {
      timeText = userTimes[props.index];
    }
    return (
      <Grid item sm={12} xs={12} container style={{ paddingTop: "10px" }}>
        <Grid item sm={12} xs={12}>
          <Typography
            className={classes.timeStamp}
            style={{ fontWeight: props.tag ? "600" : "400" }}
          >
            {timeText}
          </Typography>
        </Grid>
        {props.tag && (
          <Grid>
            <Typography
              className={classes.authorTag}
              style={{
                backgroundColor: props.fromGuide ? "#4C4C4C" : "#C4C4C4",
                color: props.fromGuide ? "#FFF" : "#000",
              }}
            >
              {props.fromGuide
                ? "Pallaksen Pöllöt"
                : translations["visitor"][language]}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        style={{ backgroundColor: "#000000B3", margin: 0, paddingBottom: "1%" }}
      >
        {/* Button for closing snow record view */}
        <Grid item xs={12} sm={12}>
          <IconButton
            aria-label="close"
            style={
              isXS
                ? { color: "white", left: "85%" }
                : {
                    color: "white",
                    left: "90%",
                    paddingTop: "1%",
                    paddingBottom: 0,
                  }
            }
            onClick={() => close()}
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        {/* Segment name */}
        <Grid item xs={12} sm={12}>
          <Typography
            className={classes.bigHeaders}
            variant="h5"
            align="center"
            component="p"
          >
            {segmentdata === null ? "Ei nimitietoa" : segmentdata.name}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ display: writeReviewEnabled === true ? "none" : "" }}
      >
        {/* Avalanche warning and icon if needed */}
        <Grid item xs={12} sm={12} align="center">
          {segmentdata === null ? null : dangerimage}
          {segmentdata === null ? null : dangertext}
        </Grid>

        {/* Forest segment view */}
        {segmentdata.name === "Metsä" && (
          <Grid item xs={12} sm={12} container className={classes.addPadding}>
            <Grid item xs={12} sm={5} container className={classes.snowInfo}>
              <Grid item xs={4} sm={3}>
                <CardMedia
                  component={"img"}
                  src={
                    process.env.PUBLIC_URL +
                    "/icons/snowtypes-and-harms/icon_forest.svg"
                  }
                  alt="lumityypin logo"
                />
              </Grid>
              <Grid item container xs={8} sm={9} className={classes.snowInfo}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    className={classes.smallHeaders}
                    variant="body1"
                    component="p"
                  >
                    {translations["forestArea"][language]}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Pohjamaasto, kommentoi näkyviin jos halutaan näyttää */}
        {/* <Typography variant="subtitle1" align="center" component="p">
          {segmentdata === null ? "Ei tietoa pohjamaastosta" : segmentdata.Maasto}
          </Typography> */}
        {!isEmpty && (
          <Grid item xs={12} sm={12} container className={classes.addPadding}>
            {ifGuideInfoExists() && (
              <>
                {/* Main snowtype info */}
                {isEnabled(1) && (
                  <Grid item xs={12} sm={6}>
                    <DisplaySnowType
                      snowType={segmentdata.update.snowTypeId1}
                      name={
                        translations[
                          getTranslationKey(segmentdata.update.snow1.name)
                        ][language]
                      }
                      skiability={segmentdata.update.snow1.skiability}
                      Main={true}
                      Guide={true}
                    />
                  </Grid>
                )}
                {/* Main snowtype info 2 */}
                {isEnabled(2) && (
                  <Grid item xs={12} sm={6}>
                    <DisplaySnowType
                      snowType={segmentdata.update.snowTypeId2}
                      name={
                        translations[
                          getTranslationKey(segmentdata.update.snow2.name)
                        ][language]
                      }
                      skiability={segmentdata.update.snow2.skiability}
                      Main={true}
                      Guide={true}
                    />
                  </Grid>
                )}
                {/* Secondary snowtypes */}
                {isEnabled(3) && (
                  <Grid item xs={12} sm={6}>
                    <DisplaySnowType
                      snowType={segmentdata.update.secondaryId1}
                      name={
                        translations[
                          getTranslationKey(segmentdata.update.snow3.name)
                        ][language]
                      }
                      skiability={segmentdata.update.snow3.skiability}
                      Main={false}
                      Guide={true}
                    />
                  </Grid>
                )}
                {isEnabled(4) && (
                  <Grid item xs={12} sm={6}>
                    <DisplaySnowType
                      snowType={segmentdata.update.secondaryId2}
                      name={
                        translations[
                          getTranslationKey(segmentdata.update.snow4.name)
                        ][language]
                      }
                      skiability={segmentdata.update.snow4.skiability}
                      Main={false}
                      Guide={true}
                    />
                  </Grid>
                )}
              </>
            )}
            {!ifGuideInfoExists() && (
              <>
                <Typography className={classes.smallText}>
                  {translations["noInformatinByPollot"][language]}
                </Typography>
                <Grid item xs={12} sm={6}>
                  <DisplaySnowType
                    snowType={segmentdata.update.snow5.id}
                    name={
                      translations[
                        getTranslationKey(segmentdata.update.snow5.name)
                      ][language]
                    }
                    skiability={segmentdata.update.snow5.skiability}
                    Main={true}
                    Guide={false}
                  />
                </Grid>

                <StonesAndBranchesWithHeader
                  option={segmentdata.update.a1Details}
                />

                <TimeStamp fromGuide={false} tag={true} index={0} />

                <Grid item xs={12} sm={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12}>
              {ifGuideInfoExists() && (
                <>
                  {/* Description of segment, this might be changed later */}
                  {description !== "" && description !== null && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      align="start"
                      className={classes.textContainer}
                    >
                      <p className={classes.normalText}>{description}</p>
                    </Grid>
                  )}

                  {/* Info about latest update time */}

                  <TimeStamp fromGuide={true} tag={true} />

                  <Grid item xs={12} sm={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                </>
              )}

              {/* User-made snowtypes */}
              {ifUserInfoExists() && (
                <Grid item xs={12} sm={12} container>
                  {ifGuideInfoExists() && (
                    <>
                      {/* Open-close infos */}
                      <Box>
                        <IconButton
                          className={classes.expandButton}
                          onClick={handleExpandClick}
                          style={{ backgroundColor: "#374B6A" }}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                          <Typography
                            className={classes.mediumText}
                            style={{ marginRight: "5px", color: "#FFF" }}
                          >
                            {translations["userReviews"][language]}
                          </Typography>
                          <Typography
                            className={
                              expanded
                                ? classes.expandOpen
                                : classes.expandClosed
                            }
                          >
                            {isXS ? "<" : "〱"}
                          </Typography>
                        </IconButton>
                      </Box>

                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Typography className={classes.smallText}>
                          {translations["infoIsBasedOnUsers"][language]}
                        </Typography>

                        {segmentdata.update.Lumi5 !== undefined && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={classes.snowComment}
                            container
                          >
                            <Grid item xs={8} sm={6}>
                              <DisplaySnowType
                                snowType={segmentdata.update.snow5.id}
                                name={
                                  translations[
                                    getTranslationKey(
                                      segmentdata.update.snow5.name
                                    )
                                  ][language]
                                }
                                skiability={segmentdata.update.snow5.skiability}
                                Main={true}
                                Guide={false}
                              />
                            </Grid>
                            <Grid item sm={2}></Grid>
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              container
                              style={{ alignContent: "center" }}
                            >
                              <StonesAndBranches
                                option={segmentdata.update.a1Details}
                              />
                            </Grid>
                            <TimeStamp fromGuide={false} index={0} />
                          </Grid>
                        )}

                        {segmentdata.update.snow6 !== undefined && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={classes.snowComment}
                            container
                          >
                            <Grid item xs={8} sm={6}>
                              <DisplaySnowType
                                snowType={segmentdata.update.snow6.id}
                                name={
                                  translations[
                                    getTranslationKey(
                                      segmentdata.update.snow6.name
                                    )
                                  ][language]
                                }
                                skiability={segmentdata.update.snow6.skiability}
                                Main={true}
                                Guide={false}
                              />
                            </Grid>
                            <Grid item sm={2}></Grid>
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              container
                              style={{ alignContent: "center" }}
                            >
                              <StonesAndBranches
                                option={segmentdata.update.a2Details}
                              />
                            </Grid>

                            <TimeStamp fromGuide={false} index={1} />
                          </Grid>
                        )}

                        {segmentdata.update.snow7 !== undefined && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={classes.snowComment}
                            container
                          >
                            <Grid item xs={8} sm={6}>
                              <DisplaySnowType
                                snowType={segmentdata.update.snow7.id}
                                name={
                                  translations[
                                    getTranslationKey(
                                      segmentdata.update.snow7.name
                                    )
                                  ][language]
                                }
                                skiability={segmentdata.update.snow7.skiability}
                                Main={true}
                                Guide={false}
                              />
                            </Grid>
                            <Grid item sm={2}></Grid>
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              container
                              style={{ alignContent: "center" }}
                            >
                              <StonesAndBranches
                                option={segmentdata.update.a3Details}
                              />
                            </Grid>
                            <TimeStamp fromGuide={false} index={2} />
                          </Grid>
                        )}

                        <Grid item xs={12} sm={12}>
                          <Divider className={classes.divider} />
                        </Grid>
                      </Collapse>
                    </>
                  )}
                </Grid>
              )}
            </Grid>

            {/* Snow review buttons */}
            {!signedUser &&
              (expanded || !ifGuideInfoExists() || !ifUserInfoExists()) && (
                <Grid item xs={12} sm={12}>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      className={classes.mediumText}
                      style={{ padding: "0px 0px 10px 5px" }}
                    >
                      {translations["didYouMoveInArea"][language]}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    spacing={isXS ? 0 : 2}
                    style={{ marginBottom: "10px" }}
                  >
                    <Grid item xs={12} sm={7}>
                      <Button
                        variant="contained"
                        className={classes.reviewButton}
                        style={{ backgroundColor: "#374B6A" }}
                        onClick={openForm}
                      >
                        {
                          translations["addEstimationOfSnowConditions"][
                            language
                          ]
                        }
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Button
                        variant="contained"
                        className={classes.reviewButton}
                        style={{ backgroundColor: "#4C4C4C" }}
                        onClick={openFeedback}
                      >
                        {translations["addAnotherObservation"][language]}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>
        )}
        {isEmpty && (
          <Grid item xs={12} sm={12} container className={classes.addPadding}>
            <Typography
              className={classes.timeStamp}
              style={{ marginBottom: "10px" }}
            >
              {translations["noObservationsInTheArea"][language]}
            </Typography>

            {!signedUser && (
              <Grid item xs={12} sm={12}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    className={classes.mediumText}
                    style={{ padding: "0px 0px 10px 5px" }}
                  >
                    {translations["didYouMoveInArea"][language]}
                  </Typography>
                </Grid>

                <Grid
                  container
                  spacing={isXS ? 0 : 2}
                  style={{ marginBottom: "10px" }}
                >
                  <Grid item xs={12} sm={7}>
                    <Button
                      variant="contained"
                      className={classes.reviewButton}
                      style={{ backgroundColor: "#374B6A" }}
                      onClick={openForm}
                    >
                      {translations["addEstimationOfSnowConditions"][language]}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Button
                      variant="contained"
                      className={classes.reviewButton}
                      style={{ backgroundColor: "#4C4C4C" }}
                      onClick={openFeedback}
                    >
                      {translations["addAnotherObservation"][language]}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}

        {/* Sponsor logos */}

        {!isEmpty && (
          <Grid container item xs={12} className={classes.sponsorContainer}>
            {sponsors !== undefined && (
              <>
                {sponsors.length >= 1 && sponsors[0].logo !== "" && (
                  <a
                    href={sponsors[0].address}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={"sponsorit/" + sponsors[0].logo}
                      alt=""
                      className={classes.sponsor}
                    />
                  </a>
                )}
                {sponsors.length >= 2 && sponsors[1].logo !== "" && (
                  <a
                    href={sponsors[1].address}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={"sponsorit/" + sponsors[1].logo}
                      alt=""
                      className={classes.sponsor}
                    />
                  </a>
                )}
                {sponsors.length >= 3 && sponsors[2].logo !== "" && (
                  <a
                    href={sponsors[2].address}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={"sponsorit/" + sponsors[2].logo}
                      alt=""
                      className={classes.sponsor}
                    />
                  </a>
                )}
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default SnowRecordView;
