/**
Käyttäjien hallintanäkymä
Tarkoituksena listata käyttäjät, muokata, poistaa ja lisätä niitä.

Luonut: Markku Nirkkonen 18.12.2020

Viimeisimmät muutokset:

Markku Nirkkonen 10.1.2021
Admin voi muuttaa muiden käyttäjien salasanoja

Markku Nirkkonen 29.12.2020
Käyttäjien muokkaustoiminnot näkyvät vain, jos kirjautuneen käyttäjän rooli on admin

Markku Nirkkonen 19.12.2020
Muokkaaminen ja poistaminen toimivat.

23.2 2023 otso tikkkanen
Added english version

**/

import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import GlobalContext from "../../context/GlobalContext";
import translations from "../../translations/translations";

import React, { useState, useEffect, useContext } from "react";
import AddUser from "./AddUser";

const useStyles = makeStyles((theme) => ({
  userCard: {
    padding: theme.spacing(1),
    maxWidth: 400,
    margin: "auto",
    marginTop: 10,
  },
  cardContainer: {
    flexGrow: 1,
    marginTop: 10,
  },
  coordinateInputs: {
    display: "flex",
  },
}));

function UserManage(props) {
  const classes = useStyles();

  // Hooks
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [selected, setSelected] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [mismatch, setMismatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [initials, setInitials] = useState(null);
  const [users, setUsers] = useState(null);
  const { language } = useContext(GlobalContext);

  const menuOpen = Boolean(anchorElMenu);

  // Muokkauslomakkeen voi lähettää, jos salasana tyhjä (ei muuteta) tai vähintään 7 merkkiä
  const editFormOK = password.length >= 7 || password === "";

  // Käyttäjätietojen automaattinen haku, kun komponentti on renderöity
  useEffect(() => {
    fetchUsers();
  }, []);

  /*
   * Event handlers
   */

  // Käyttäjät päivitetään
  const fetchUsers = async () => {
    const users = await fetch("api/user/all", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token,
      },
    });
    const usersdata = await users.json();

    setUsers(usersdata);
  };

  // Käyttäjän valikon avaaminen, tarkentaa samalla valitun käyttäjän
  const handleMenu = (event, item) => {
    setSelected(item);

    // Alkuperäisten arvojen alustaminen muutosten perumista varten
    if (!editOpen) {
      var initialName = item.firstName;
      var initialSurname = item.surname;
      var initialEmail = item.email;

      setInitials({
        firstName: initialName,
        surname: initialSurname,
        email: initialEmail,
      });
    }
    setAnchorElMenu(event.currentTarget);
  };

  // Menun sulkeminen nollaa valitun käyttäjän
  const handleMenuClose = () => {
    setAnchorElMenu(null);
    setSelected(null);
    setInitials(null);
  };

  // Käyttäjän poiston api-kutsu
  const handleDelete = () => {
    const fetchDelete = async () => {
      await fetch("api/user/" + selected.id, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.token,
        },
      });
    };
    fetchDelete();

    // Poiston jälkeen käyttäjätiedot päivitetään
    fetchUsers();

    closeDelete();
  };

  // avataan käyttäjän poistodialogi
  const openDelete = () => {
    setDeleteOpen(true);
  };

  // Suljetaan poistodialogi ja nollataan käyttäjän valinta
  const closeDelete = () => {
    setAnchorElMenu(null);
    setDeleteOpen(false);
    setSelected(null);
    setInitials(null);
  };

  // Käsitellään käyttäjän muokkaus
  const handleEdit = () => {
    if (password === confirm) {
      // Tiedot  tulevat hookeista
      const data = {
        firstName: firstName === null ? initials.firstName : firstName,
        surname: surname === null ? initials.surname : surname,
        email: email === null ? initials.email : email,
        id: selected.id,
      };
      if (password !== "") {
        data.Salasana = password;
      }

      // Käyttäjän muokkaamisen api-kutsu
      const fetchEditUser = async () => {
        const response = await fetch("api/user/" + selected.id, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + props.token,
          },
          body: JSON.stringify(data),
        });
        const res = await response.json();
        console.log(res);
      };
      fetchEditUser();

      // Käyttäjät haetaan uudelleen muutoksien jälkeen
      fetchUsers();

      setAnchorElMenu(null);
      closeEdit();
    } else {
      setMismatch(true);
    }
  };

  // Avataan muokkausdialogi
  const openEdit = () => {
    setEditOpen(true);
  };

  // Kun muokkausdialogi suljetaan, nollataan valinnat ja suljetaan dialogi
  const closeEdit = () => {
    setFirstName(null);
    setSurname(null);
    setEmail(null);
    setInitials(null);
    setAnchorElMenu(null);
    setEditOpen(false);
    setSelected(null);
    setPassword("");
    setConfirm("");
    setMismatch(false);
  };

  // etunimen päivitys
  const updateFirstName = (event) => {
    if (event.target.value === "") {
      setFirstName(initials.firstName);
    } else {
      setFirstName(event.target.value);
    }
  };

  // sukunimen päivitys
  const updateLastName = (event) => {
    if (event.target.value === "") {
      setSurname(initials.surname);
    } else {
      setSurname(event.target.value);
    }
  };

  // sähköpostin päivittäminen
  const updateEmail = (event) => {
    if (event.target.value === "") {
      setEmail(initials.email);
    } else {
      setEmail(event.target.value);
    }
  };

  // Vaihtaa salasanakentän näkyvyyden (päälle/pois)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Päivitetään salasanaa, kun sen arvo muuttuu
  const updatePassword = (event) => {
    setPassword(event.target.value);
  };

  // Päivitetään varmennetta, kun sitä muutetaan
  const updateConfirm = (event) => {
    setConfirm(event.target.value);
  };

  // Renderöinti

  // Vain admin-käyttäjä näkee muokkaustoiminnot
  if (props.role === "admin") {
    return (
      <div>
        {/* Painike, mistä voi lisätä segmentin */}
        <Box>
          <AddUser token={props.token} fetchUsers={fetchUsers} />
        </Box>

        {/* Käyttäjäkortit */}
        <Box className={classes.cardContainer}>
          <Grid container spacing={0}>
            {/* Luodaan jokaiselle käyttäjälle oma kortti */}
            {users === null ? (
              <div />
            ) : (
              users.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} sm={4}>
                    <Card className={classes.userCard}>
                      <CardHeader
                        title={item.firstName + " " + item.surname}
                        subheader={item.email}
                        action={
                          <IconButton
                            id={item.id}
                            aria-label="close"
                            onClick={(event) => handleMenu(event, item)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        }
                      />

                      {/* Valikko kortin lisätoiminnoille */}
                      <Menu
                        anchorEl={anchorElMenu}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={menuOpen}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={openEdit}>
                          {translations["edit"][language]}
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => openDelete()}>
                          {translations["delete"][language]}
                        </MenuItem>
                      </Menu>

                      <CardContent>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          align="left"
                          component="p"
                        >
                          {item.role !== null
                            ? translations["role"][language] + item.role
                            : translations["noDefinedRole"][language]}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Box>

        {/* Käyttäjän poistodialogi */}
        <Dialog onClose={closeDelete} open={deleteOpen}>
          <DialogTitle id="delete_user">
            {translations["deleteUser"][language]}
          </DialogTitle>
          <Typography>
            {translations["deleteUserAndDataRelated"][language]}
          </Typography>
          <DialogActions>
            <Divider />
            <Button id={"deleteClose"} onClick={closeDelete}>
              {translations["cancel"][language]}
            </Button>
            <Button
              variant="contained"
              color="primary"
              id={"delete"}
              onClick={handleDelete}
            >
              {translations["delete"][language]}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Muokkausdialogi (lomake) */}
        <Dialog onClose={closeEdit} open={editOpen}>
          <DialogTitle id="edit_user">
            {translations["editUser"][language]}
          </DialogTitle>
          <Typography variant="caption">
            {translations["leaveBlank"][language]}
          </Typography>
          <Typography variant="caption">
            {translations["tooShortPassword"][language]}
          </Typography>
          <FormControl>
            <InputLabel htmlFor="firstname">
              {translations["changeFirstName"][language]}
            </InputLabel>
            <Input
              id="firstname"
              type="text"
              onChange={updateFirstName}
              placeholder={selected !== null ? selected.firstName : ""}
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="lastname">
              {translations["changeLastName"][language]}
            </InputLabel>
            <Input
              id="lastname"
              type="text"
              onChange={updateLastName}
              placeholder={selected !== null ? selected.surname : ""}
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="email">
              {translations["changeEmail"][language]}
            </InputLabel>
            <Input
              id="email"
              type="text"
              onChange={updateEmail}
              placeholder={selected !== null ? selected.email : ""}
            />
          </FormControl>
          <FormControl error={mismatch}>
            <InputLabel htmlFor="standard-adornment-password">
              {translations["newPassword"][language]}
            </InputLabel>
            <Input
              id="standard-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={updatePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {mismatch ? (
            <FormHelperText>
              {translations["passwordsDoNotMatch"][language]}
            </FormHelperText>
          ) : (
            <div />
          )}

          <FormControl error={mismatch}>
            <InputLabel htmlFor="confirm">
              {translations["confirmNewPassword"][language]}
            </InputLabel>
            <Input
              id="confirm"
              type="password"
              value={confirm}
              onChange={updateConfirm}
            />
          </FormControl>

          <DialogActions>
            <Divider />
            <Button id={"editClose"} onClick={closeEdit}>
              {translations["cancel"][language]}
            </Button>
            <Button
              variant="contained"
              color="primary"
              id={"save_edit"}
              onClick={handleEdit}
              disabled={!editFormOK}
            >
              {translations["saveChanges"][language]}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    // Operator-tason käyttäjä ei voi hallita muita käyttäjiä
    return (
      <Typography variant="h6">
        {translations["userManagementRequiresAdminRights"][language]}
      </Typography>
    );
  }
}

export default UserManage;
